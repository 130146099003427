
import { Route, Redirect } from 'react-router-dom'

export default function PrivateRoute({ auth, component: RouteComponent, ...rest }) {



    return (
        <Route
            {...rest}
            component={(props) => {
                return auth
                    ? <RouteComponent {...props} />
                    : <Redirect to='/login' />
            }}

        />
    )
}





























// import { Route, Redirect } from 'react-router-dom';

// export default function PrivateRoute({ auth, component: RouteComponent, ...rest }) {
//     return (
//         <Route {...rest}
//             component={(props) => {
//                 return auth
//                     ? <RouteComponent {...props} />
//                     : <Redirect to='/login' />
//             }}
//         />
//     )
// }
